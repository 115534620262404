import React, { useEffect, forwardRef, useState } from 'react';
import $ from 'jquery';

const MaxHeightContainer = forwardRef(
  ({ children, id, offset, ...rest }, ref) => {
    const [height, setHeight] = useState('600px');

    const calculateHeight = () => {
      const windowHeight = $(window).height();
      const navHeight = $('#main-nav').height();
      return windowHeight - (navHeight + offset + 80);
    };

    useEffect(() => {
      // Debounce the resize handler
      let resizeTimer;

      const handleResize = () => {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
          setHeight(`${calculateHeight()}px`);
        }, 100);
      };

      // Set initial height
      setHeight(`${calculateHeight()}px`);

      // Bind resize event
      $(window).on('resize', handleResize);

      return () => {
        clearTimeout(resizeTimer);
        $(window).off('resize', handleResize);
      };
    }, [offset]); // Add offset to dependencies

    return (
      <div style={{ height, overflowY: 'scroll' }} id={id} ref={ref} {...rest}>
        {children}
      </div>
    );
  },
);

export default MaxHeightContainer;
