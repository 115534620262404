import React, { useState } from 'react';
import { JsonForm } from '@deecide/redux-json-form';
import { Alert } from 'react-bootstrap';
import { getFormLabels, getFormOptions } from 'components/preview/options';
import { useRequest } from 'services/request/hook';
import isString from 'lodash/isString';
const PreviewSchema = ({ schema, translations = {} }) => {
  //check schema valid json
  const { request } = useRequest();
  const [locale, setLocale] = useState('fr');
  const [formName, setFormName] = useState('previewForm');
  const [disabled, setDisabled] = useState(false);
  const [values, setValues] = useState({});

  if (!schema) {
    return <Alert variant="danger">Schema not found</Alert>;
  }
  let parsedSchema;
  try {
    parsedSchema = isString(schema) ? JSON.parse(schema) : schema;
  } catch (e) {
    return <Alert variant="danger">Schema is not valid JSON</Alert>;
  }

  return (
    <JsonForm
      locale={locale}
      onSubmit={(values) => {
        console.log('onSubmit', values);
      }}
      form={formName}
      translations={translations}
      schema={parsedSchema}
      initialValues={{}}
      storeContext={request}
      disabled={disabled}
      submitValidations={{}}
      onSubmitSuccess={() => {}}
      onSubmitFail={() => {}}
      enableReinitialize={false}
      formLabels={getFormLabels()}
      options={getFormOptions(locale)}
      editor={null}
      onSetToSchema={() => {}}
      onEdit={() => {}}
      showFormErrors={false}
      onChange={(newValues) => {
        setValues((prevValues) => {
          return { ...prevValues, ...newValues };
        });
      }}
    />
  );
};

export default PreviewSchema;
