import React, { useRef, useCallback, useMemo, useEffect } from 'react';
import Editor from '@monaco-editor/react';
import './schema_input.scss';
import { assertions, isIterableArray } from './schema_input_constants';
import { useMonaco } from '@monaco-editor/react';
import { uniq, flattenDeep, isString } from 'lodash';

const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
};

const getTranslationSuggestions = (translations) => {
  const allTranslations = uniq(
    flattenDeep(
      Object.keys(translations).map((locale) =>
        Object.keys(translations[locale]),
      ),
    ),
  );
  return allTranslations.map((key) => ({
    label: key,
    insertText: key,
  }));
};

const SchemaInput = ({
  value,
  onChange,
  minHeight = '100vh',
  translations = {},
  availableData = [],
}) => {
  const monaco = useMonaco();
  const editorRef = useRef(null);
  const workflowDataCompletionItemProvider = useRef(null);
  const assertionCompletionItemProvider = useRef(null);
  const formatDocument = useCallback(() => {
    const editor = editorRef.current;
    const model = editor.getModel();
    if (model) {
      const content = model.getValue();
      try {
        JSON.parse(content);
        editor.getAction('editor.action.formatDocument').run();
      } catch (error) {}
    }
  }, []);

  const translationsSuggestions = useMemo(
    () => (translations ? getTranslationSuggestions(translations) : []),
    [translations],
  );

  const availableAssertions = useMemo(
    () =>
      assertions.map(({ assertion }) => ({
        label: assertion,
        insertText: assertion,
      })),
    [],
  );
  const availableDataSuggestions = useMemo(
    () =>
      availableData
        ? availableData.map((data) => ({ label: data, insertText: data }))
        : [],
    [availableData],
  );

  const handleEditorDidMount = useCallback(
    (editor, monaco) => {
      editorRef.current = editor;
      formatDocument();
      editor.onDidChangeModelContent(
        debounce(() => {
          formatDocument();
        }, 1000),
      );
    },
    [formatDocument],
  );

  useEffect(() => {
    if (monaco) {
      assertionCompletionItemProvider.current =
        monaco.languages.registerCompletionItemProvider('json', {
          triggerCharacters: ['"'],

          provideCompletionItems: function (model, position) {
            const textUntilPosition = model.getValueInRange({
              startLineNumber: position.lineNumber,
              startColumn: 1,
              endLineNumber: position.lineNumber,
              endColumn: position.column,
            });

            if (/^.*"assertion"\s*:\s*"$/.test(textUntilPosition)) {
              return {
                suggestions: availableAssertions.map((assertion) => ({
                  ...assertion,
                  kind: monaco.languages.CompletionItemKind.Value,
                  range: {
                    startLineNumber: position.lineNumber,
                    startColumn: position.column,
                    endLineNumber: position.lineNumber,
                    endColumn: position.column,
                  },
                })),
              };
            }

            return { suggestions: [] };
          },
        });
      workflowDataCompletionItemProvider.current =
        monaco.languages.registerCompletionItemProvider('json', {
          triggerCharacters: [':'],
          provideCompletionItems: function (model, position) {
            const textUntilPosition = model.getValueInRange({
              startLineNumber: position.lineNumber,
              startColumn: position.column - 2,
              endLineNumber: position.lineNumber,
              endColumn: position.column,
            });

            if (
              textUntilPosition === 't:' &&
              isIterableArray(translationsSuggestions)
            ) {
              return {
                suggestions: translationsSuggestions.map((suggestion) => ({
                  ...suggestion,
                  kind: monaco.languages.CompletionItemKind.Text,
                })),
              };
            }
            if (
              textUntilPosition === 'v:' &&
              isIterableArray(availableDataSuggestions)
            ) {
              return {
                suggestions: availableDataSuggestions.map((suggestion) => ({
                  ...suggestion,
                  kind: monaco.languages.CompletionItemKind.Text,
                  additionalTextEdits: [
                    {
                      range: {
                        startLineNumber: position.lineNumber,
                        startColumn: position.column - 2,
                        endLineNumber: position.lineNumber,
                        endColumn: position.column,
                      },
                      text: '',
                    },
                  ],
                })),
              };
            }
            return { suggestions: [] };
          },
        });
      return () => {
        assertionCompletionItemProvider.current.dispose();
        workflowDataCompletionItemProvider.current.dispose();
      };
    }
  }, [monaco]);

  return (
    <div className="monaco-container">
      <Editor
        className="json-editor"
        defaultLanguage="json"
        theme="vs-dark"
        defaultValue={
          value
            ? !isString(value)
              ? JSON.stringify(value, null, 2)
              : JSON.stringify(JSON.parse(value), null, 2)
            : '{}'
        } // Modified line
        onMount={handleEditorDidMount}
        options={{
          tabSize: 2,
        }}
        height={minHeight}
        onChange={(value) => {
          try {
            onChange(JSON.parse(value));
          } catch (e) {
            //console.log('error', e);
          }
        }}
      />
    </div>
  );
};

export default SchemaInput;
