import React from 'react';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import ScenesError500 from 'scenes/error/500';
import ScenesLanskyBuilder from 'scenes/lansky/builder';
import ScenesWatsonBuilder from 'scenes/watson/builder';
import AuthenticatedRoot from 'components/authenticated/root';
import ScenesWorkflowsStatuses from 'scenes/workflow/statuses';
import ScenesAPIDebug from 'scenes/api_debug';
import ScenesWorkflowEmailBuilder from 'scenes/workflow/email_builder';
import WorkflowProvider from 'services/workflow/provider';
import ScenesWorkflowTranslations from 'scenes/workflow/translations';
import ScenesWorkflowFlow from 'scenes/workflow/flow';
import ScenesSandbox from 'scenes/sandbox';
import ScenesRequestNavigation from 'scenes/request/navigation';
import RequestProvider from 'services/request/provider';
import ScenesWorkflowApiDocumentation from 'scenes/workflow/api_documentation';
import ScenesEmbedBuilder from 'scenes/embed_builder';
import ScenesWorkflowHTMLGenerator from 'scenes/workflow/html_generator';
import ScenesWorkflowTester from 'scenes/workflow/tester';
import ScenesDataOnly from 'scenes/data_only';
import ScenesPreviewSchema from 'scenes/preview/schema';
import ScenesPaulAdmin from 'scenes/paul/admin';
import PaulAdminProvider from 'services/paul_admin/provider';
import FirebaseProvider from 'services/firebase/provider';
import ViviumCatnatScene from 'scenes/vivium_catnat';

const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthenticatedRoot />,
    children: [
      {
        path: 'data_only',
        element: <ScenesDataOnly />,
      },
      {
        index: true,
        path: 'lansky',
        element: <ScenesLanskyBuilder />,
      },
      {
        path: 'watson',
        element: <ScenesWatsonBuilder />,
      },
      {
        path: 'workflow',
        element: (
          <WorkflowProvider>
            <Outlet />
          </WorkflowProvider>
        ),
        children: [
          {
            path: 'statuses',
            element: <ScenesWorkflowsStatuses />,
          },
          {
            path: 'email',
            element: <ScenesWorkflowEmailBuilder />,
          },
          {
            path: 'flow',
            element: <ScenesWorkflowFlow />,
          },
          {
            path: 'translations',
            element: <ScenesWorkflowTranslations />,
          },
          {
            path: 'api_documentation',
            element: <ScenesWorkflowApiDocumentation />,
          },
          {
            path: 'html_generator',
            element: <ScenesWorkflowHTMLGenerator />,
          },
          {
            path: 'tester',
            element: <ScenesWorkflowTester />,
          },
        ],
      },
      {
        path: 'request',
        element: (
          <RequestProvider>
            <Outlet />
          </RequestProvider>
        ),
        children: [
          {
            path: 'navigation',
            element: <ScenesRequestNavigation />,
          },
        ],
      },
      {
        path: 'api_debug',
        element: <ScenesAPIDebug />,
      },
      {
        path: 'embed_builder',
        element: <ScenesEmbedBuilder />,
      },
      {
        path: '/sandbox',
        element: <ScenesSandbox />,
      },
      {
        path: 'vivium_catnat',
        element: <ViviumCatnatScene />,
      },
      {
        path: 'preview/schema',
        element: (
          <RequestProvider>
            <FirebaseProvider>
              <ScenesPreviewSchema />
            </FirebaseProvider>
          </RequestProvider>
        ),
      },
      {
        path: 'paul',
        element: (
          <PaulAdminProvider>
            <Outlet />
          </PaulAdminProvider>
        ),
        children: [
          {
            path: 'admin',
            element: <ScenesPaulAdmin />,
          },
          {
            path: '*',
            element: <Navigate to="/admin" replace />,
          },
        ],
      },
      {
        path: '/',
        element: <Navigate to="/lansky" replace />,
      },
      {
        path: '*',
        element: <Navigate to="/lansky" replace />,
      },
    ],
    errorElement: <ScenesError500 />,
  },
]);

export default router;
