import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PreviewSchema from 'components/preview/schema';
import WithStore from 'scenes/preview/with_store';
import { ButtonGroup } from 'react-bootstrap';
import Card from 'components/ui/card';
import { useI18n } from 'services/i18n/hook';
import { Row, Col } from 'react-bootstrap';
import Button from 'components/ui/button';
import SchemaInput from 'components/ui/schema_input';
import RequestSearchForm from 'components/request/search/form';
import { getWorkflow } from 'requests/workflow';
import { useRequest } from 'services/request/hook';
import { useFirebase } from 'services/firebase/hook';
import Loader from 'components/ui/loader';
import copy from 'clipboard-copy';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import has from 'lodash/has';
import merge from 'lodash/merge';
import { toast } from 'react-toastify';

const notify = (text) => {
  toast.success(text, {
    position: 'bottom-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: 'colored',
  });
};

const PREVIEW_CONFIG_KEY = 'previewConfig';

const PreviewSchemaScene = () => {
  const { t } = useI18n();
  const [searchParams] = useSearchParams();
  const [locale, setLocale] = useState('fr');
  const previewId = searchParams.get('previewId');
  const [schemaToUse, setSchemaToUse] = useState({});
  const [previewLoading, setPreviewLoading] = useState(true);
  const [creatingShareLink, setCreatingShareLink] = useState(false);
  const {
    error,
    loading,
    flattenRequestData,
    loadedAt,
    request,
    env,
    getRequest,
    requestId,
  } = useRequest();
  const [workflowData, setWorkflowData] = useState({});
  const { createSchemaPreview, getSchemaPreview } = useFirebase();

  const getWorkflowData = (workflowId) => {
    getWorkflow(workflowId, env).then((response) => {
      setWorkflowData(response.data);
    });
  };
  useEffect(() => {
    if (
      request?.workflow_id &&
      workflowData?.workflow?.id !== request.workflow_id
    ) {
      getWorkflowData(request.workflow_id);
    }
  }, [request?.workflow_id]);

  useEffect(() => {
    if (previewId) {
      setPreviewLoading(true);
      getSchemaPreview(previewId)
        .then((response) => {
          if (response && response.schema) {
            setSchemaToUse(response.schema);
          }
          setPreviewLoading(false);
        })
        .catch(() => {
          setPreviewLoading(false);
        });
    } else {
      setPreviewLoading(false);
    }
  }, [previewId]);

  useEffect(() => {
    const savedConfig = localStorage.getItem(PREVIEW_CONFIG_KEY);
    if (savedConfig) {
      const {
        schemaToUse: savedSchema,
        requestId: savedRequestId,
        env: savedEnv,
      } = JSON.parse(savedConfig);
      if (savedSchema) {
        setSchemaToUse(savedSchema);
      }
      if (savedRequestId && savedEnv) {
        getRequest(savedRequestId, savedEnv);
      }
    }
  }, []);

  useEffect(() => {
    if (schemaToUse) {
      localStorage.setItem(
        PREVIEW_CONFIG_KEY,
        JSON.stringify({ schemaToUse, requestId, env }),
      );
    }
  }, [schemaToUse]);

  const createShareLink = async () => {
    setCreatingShareLink(true);
    const id = await createSchemaPreview(
      JSON.stringify(JSON.parse(schemaToUse)),
    );
    const url = new URL(window.location.href);
    url.searchParams.set('previewId', id);
    await copy(url.toString());
    notify('Sharable link copied to clipboard');
    setCreatingShareLink(false);
  };

  const preview = !isNil(schemaToUse)
    ? has(schemaToUse, 'schema')
      ? get(schemaToUse, 'schema')
      : schemaToUse
    : {};

  const previewTranslations = !isNil(schemaToUse)
    ? has(schemaToUse, 'translations')
      ? get(schemaToUse, 'translations')
      : {}
    : {};

  const workflowTranslations = workflowData?.workflow?.translations || {};

  return (
    <WithStore>
      <Card
        title={<h5>{t('scenes.preview.schema.title')}</h5>}
        bodyClassName="p-20"
        actions={
          <ButtonGroup>
            <select
              value={locale}
              onChange={(e) => {
                console.log('select', ev.target.value);
                setLocale(e.target.value);
              }}
            >
              <option value="fr">FR</option>
              <option value="nl">NL</option>
            </select>
            <RequestSearchForm />
            <Button
              size="sm"
              onClick={createShareLink}
              loading={creatingShareLink}
            >
              Share
            </Button>
          </ButtonGroup>
        }
      >
        {previewLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Loader />
          </div>
        ) : (
          <Row>
            <Col xs={12} md={6}>
              <PreviewSchema
                schema={preview}
                translations={merge(previewTranslations, workflowTranslations)}
                availableData={flattenRequestData}
                locale={locale}
              />
            </Col>
            <Col xs={12} md={6}>
              <SchemaInput
                value={schemaToUse}
                onChange={setSchemaToUse}
                translations={workflowData?.workflow?.translations}
                availableData={
                  flattenRequestData ? Object.keys(flattenRequestData) : []
                }
              />
            </Col>
          </Row>
        )}
      </Card>
    </WithStore>
  );
};

export default PreviewSchemaScene;
