import React, { useEffect, useState } from 'react';
import {
  UncontrolledAccordion,
  AccordionItem,
  AccordionBody,
  AccordionHeader,
  Table,
  Badge,
  Alert,
  Button,
  ButtonGroup,
} from 'reactstrap';
import get from 'lodash/get';
import countBy from 'lodash/countBy';
import flatten from 'lodash/flatten';

import results_2021 from './1639037021912_result.json';
import results_2019 from './1637059761234_result.json';
import results_2022 from './1647941037566_result.json';
import results_2025 from './1737536654104_result.json';
import JsonViewer from 'components/ui/json_viewer';

const parseResults = (results) => {
  return results.map((result, resultId) => {
    const keys = Object.keys(result);
    return keys.map((key) => {
      const resultForKey = result[key];
      const expectedCodeRegion = resultForKey.input.frz.code_region;
      const receivedCodeRegion = get(
        resultForKey,
        'output.frz.code_region',
        'error',
      );
      const expectedTarifClassIndex =
        resultForKey.input.frz.tarif_class_index_property_type;
      const receivedTarifClassIndex = get(
        resultForKey,
        'output.frz.tarif_class_index_property_type',
        'error',
      );

      const parseError = get(resultForKey, 'output.error', null);

      const warning = get(resultForKey, 'output.frz.__warning', null);

      const sameCodeRegion = expectedCodeRegion === receivedCodeRegion;

      const sameTarifClassIndex =
        expectedTarifClassIndex === receivedTarifClassIndex;

      const matchError =
        !sameCodeRegion || !sameTarifClassIndex ? 'Match error' : false;
      const isError = parseError || warning || matchError;
      const itemId = `${resultId}`;

      return {
        expectedCodeRegion,
        receivedCodeRegion,
        expectedTarifClassIndex,
        receivedTarifClassIndex,
        isError,
        parseError,
        warning,
        itemId,
        key,
        output: resultForKey.output,
      };
    });
  });
};

const resultList = [
  {
    name: '2019',
    results: results_2019,
  },
  {
    name: '2021',
    results: results_2021,
  },
  {
    name: '2022',
    results: results_2022,
  },
  {
    name: '2025',
    results: results_2025,
  },
];

const ViviumCatnatAutomaticTests = () => {
  const [activeResult, setActiveResult] = useState(resultList[1]);
  const [parsedResults, setParsedResults] = useState([]);
  const [showSuccessTests, toggleShowSuccessTests] = useState(false);
  const [errorFilters, setErrorFilters] = useState([]);
  const [count, setCount] = useState([]);
  const [totalErrorCount, setTotalErrorCount] = useState(0);
  const [activeFilters, setActiveFilters] = useState([]);
  const init = (resultToInit) => {
    setActiveResult(resultToInit);
    console.log('resultToInit.name', resultToInit.name);
    const parsed = flatten(parseResults(resultToInit.results));
    const count = countBy(parsed, 'isError');
    const countKeys = Object.keys(count);
    let newCount = {};
    countKeys.map((key) => {
      if (key === 'true') {
        newCount['Match error'] = count[key];
      }
      if (key !== 'false' && key !== 'true') {
        newCount[key] = count[key];
      }
    });
    setCount(newCount);
    setErrorFilters(Object.keys(newCount));
    setParsedResults(parsed);
    let totalCount = 0;
    Object.keys(newCount).forEach((countKey) => {
      totalCount = totalCount + newCount[countKey];
    });
    setTotalErrorCount(totalCount);
  };
  useEffect(() => {
    init(resultList[resultList.length - 1]);
  }, []);
  if (parsedResults.length === 0) {
    return null;
  }

  const toggleFilter = (filter) => {
    setActiveFilters((prevActiveFilters) => {
      if (prevActiveFilters.includes(filter)) {
        return prevActiveFilters.filter(
          (prevActiveFilter) => prevActiveFilter !== filter,
        );
      } else {
        return [...prevActiveFilters, filter];
      }
    });
  };

  return (
    <div className="bg-light p-2">
      <div className="mb-2 d-flex align-items-center justify-content-center">
        <ButtonGroup>
          {resultList.map((result) => {
            const isActive = result.name === activeResult.name;
            return (
              <Button
                key={result.name}
                outline={!isActive}
                color={isActive ? 'primary' : 'secondary'}
                onClick={() => {
                  init(result);
                }}
              >
                {result.name}
              </Button>
            );
          })}
        </ButtonGroup>
      </div>
      <div className="mb-2 d-flex align-items-center">
        <ButtonGroup>
          {errorFilters.map((filter) => {
            const isActive = activeFilters.includes(filter);
            return (
              <Button
                key={filter}
                outline={!isActive}
                color={isActive ? 'primary' : 'secondary'}
                onClick={() => toggleFilter(filter)}
              >
                {filter}{' '}
                <Badge pill className="ms-1" color="danger">
                  {count[filter]}
                </Badge>
              </Button>
            );
          })}
        </ButtonGroup>
        <p className="mb-0 ms-2">
          {totalErrorCount} errors / {parsedResults.length} test cases
        </p>
        <Button
          className="ms-auto"
          color={showSuccessTests ? 'secondary' : 'primary'}
          onClick={() => toggleShowSuccessTests(!showSuccessTests)}
        >
          {showSuccessTests ? 'Hide success tests' : 'Show success tests'}
        </Button>
      </div>
      <UncontrolledAccordion>
        {parsedResults
          .filter((result) => (showSuccessTests ? !!result : result.isError))
          .filter((result) => {
            if (activeFilters.length === 0) {
              return result;
            }
            return activeFilters.includes(result.isError);
          })
          .map((result, id) => {
            if (id === 0) {
              console.log('result', result);
            }
            const {
              expectedCodeRegion,
              receivedCodeRegion,
              expectedTarifClassIndex,
              receivedTarifClassIndex,
              isError,
              parseError,
              itemId,
              key,
              output,
              warning,
            } = result;

            return (
              <AccordionItem key={key}>
                <AccordionHeader targetId={itemId}>
                  {isError ? (
                    <Badge color="danger" className="me-1">
                      Error
                    </Badge>
                  ) : (
                    <Badge color="success" className="me-1">
                      Success
                    </Badge>
                  )}
                  {key}
                  {parseError && (
                    <span className="text-danger m-2">{` ${parseError}`}</span>
                  )}
                  {warning && (
                    <span className="text-danger m-2">{` ${warning}`}</span>
                  )}
                </AccordionHeader>
                <AccordionBody accordionId={itemId}>
                  <Alert color={isError ? 'danger' : 'info'}>
                    {parseError ? (
                      <p className="mb-0">Error : {parseError}</p>
                    ) : (
                      <>
                        <p className="mb-0">
                          Expected tarif class index{' '}
                          <b>"{expectedTarifClassIndex}"</b> received{' '}
                          <b>"{receivedTarifClassIndex}"</b>
                        </p>
                        <p className="mb-0">
                          Expected code region <b>"{expectedCodeRegion}"</b>{' '}
                          received <b>"{receivedCodeRegion}"</b>
                        </p>
                      </>
                    )}
                  </Alert>
                  <Table className="mb-0" bordered size="sm">
                    <thead>
                      <tr>
                        <th>offset_zipcode.txt</th>
                        <th>street_pseg.txt</th>
                        <th>segmentinfo.txt</th>
                        <th>intm_HI.txt</th>
                        <th>subfrzinfo_fl.txt</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <JsonViewer
                            json={get(output, 'debug.offset_zip_code', '')}
                          />
                        </td>
                        <td>
                          <JsonViewer
                            json={get(output, 'debug.street_pseg', '')}
                          />
                        </td>
                        <td>
                          <JsonViewer
                            json={get(output, 'debug.segmentinfo', '')}
                          />
                        </td>
                        <td>
                          <JsonViewer json={get(output, 'debug.intm_HI', '')} />
                        </td>
                        <td>
                          <JsonViewer
                            json={get(output, 'debug.subfrzinfo_fl', '')}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </AccordionBody>
              </AccordionItem>
            );
          })}
      </UncontrolledAccordion>
    </div>
  );
};

export default ViviumCatnatAutomaticTests;
