import React, { useState } from 'react';
import ViviumCatnatAutomaticTests from 'components/vivium_catnat/automatic_tests';

const ViviumCatnatScene = () => {
  const [activeTab, setActiveTab] = useState('automatic');
  const tabs = [
    {
      label: 'Manual testing',
      slug: 'manual',
    },
    {
      label: 'Automatic tests',
      slug: 'automatic',
    },
  ];

  return (
    <>
      <ViviumCatnatAutomaticTests />
    </>
  );
};

export default ViviumCatnatScene;
