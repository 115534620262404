import React, { useEffect, useRef, useState } from 'react';
import { Form, InputGroup, Table, Dropdown, Badge } from 'react-bootstrap';
import { useI18n } from 'services/i18n/hook';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ViewportList } from 'react-viewport-list';
import './table.scss';
import $ from 'jquery';
import MaxHeightContainer from 'components/ui/max_height_container';
import { useRequest } from 'services/request/hook';
import RequestDataRow from './row';
import { useApplication } from 'services/application/hook';

const CURRENT_USERS_LIST = [
  {
    value: 'creator',
    label: 'Creator',
  },
  {
    value: 'provider_mananger',
    label: 'Provider Manager',
  },
  {
    value: 'seller_manager',
    label: 'Seller Manager',
  },
];

const RequestDataTable = ({ dataActions: DataActions, onPathSelect }) => {
  const {
    request,
    flattenRequestData,
    setCurrentUser,
    currentUser,
    availableWorkflowData,
  } = useRequest();
  const { inIframe } = useApplication();
  const timeoutSearch = useRef(null);
  const { t } = useI18n();
  const ref = useRef(null);
  const [filters, setFilters] = useState({
    constants: false,
    translations: false,
    step_metadata: false,
  });
  const [inMaster, setInMaster] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState(flattenRequestData);
  const [items, setItems] = useState([]);
  const hasMasterData = !isEmpty(get(request, 'master'));

  const initItems = (itemsToUse = null) => {
    setItems(
      orderBy(
        Object.keys(itemsToUse || flattenRequestData).map((path, index) => ({
          index,
          path,
        })),
        'path',
      ),
    );
  };

  const onFilterChange = () => {
    if (timeoutSearch.current) {
      clearTimeout(timeoutSearch.current);
    }
    timeoutSearch.current = setTimeout(() => {
      setFilteredData(() => {
        let newFilteredData = {};
        const keys = uniq(
          flatten([
            ...Object.keys(flattenRequestData),
            ...availableWorkflowData,
          ]),
        );
        keys.forEach((key) => {
          if (search.length > 0 && !key.includes(search)) {
            return;
          }
          if (!filters.constants && key.startsWith('constants')) {
            return;
          }
          if (!filters.translations && key.startsWith('translations')) {
            return;
          }
          if (!filters.step_metadata && key.startsWith('__')) {
            return;
          }
          newFilteredData[key] = flattenRequestData[key];
        });
        initItems(newFilteredData);

        return newFilteredData;
      });
    }, 300);
  };

  const onSearch = (newSearch) => {
    setSearch(newSearch);
  };

  const availableCurrentUserList = CURRENT_USERS_LIST.filter(
    (user) => !isEmpty(get(request, user.value)),
  );

  const toggleMaster = () => {
    setInMaster(!inMaster);
  };

  useEffect(() => {
    let newFilteredData = {};
    Object.keys(flattenRequestData).forEach((key) => {
      if (inMaster && !key.startsWith('master')) {
        return;
      }
      if (!inMaster && key.startsWith('master')) {
        return;
      }
      newFilteredData[key] = flattenRequestData[key];
    });
    initItems(newFilteredData);
  }, [inMaster]);

  useEffect(() => {
    onFilterChange();
  }, [filters, search, flattenRequestData]);

  useEffect(() => {
    setCurrentUser(availableCurrentUserList[0].value);
  }, []);

  return (
    <>
      <div className="p-2 bg-200" id="request-data-search-bar">
        <InputGroup>
          <Form.Control
            size="sm"
            placeholder={t('components.request.data.table.search')}
            value={search}
            onChange={(ev) => onSearch(ev.target.value)}
          />
          <Dropdown autoClose="outside">
            <Dropdown.Toggle variant="secondary" size="sm">
              <FontAwesomeIcon icon={['fa', 'filter']} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-2 text-900">
              <Form.Check
                size="sm"
                type="switch"
                className="mb-0"
                onChange={(ev) => {
                  setFilters((prevFilters) => {
                    return {
                      ...prevFilters,
                      translations: ev.target.checked,
                    };
                  });
                }}
                value={filters.translations}
                id="show_translations"
                label={t('components.request.data.table.show_translations')}
              />
              <Form.Check
                size="sm"
                type="switch"
                className="mb-0"
                onChange={(ev) => {
                  setFilters((prevFilters) => {
                    return {
                      ...prevFilters,
                      constants: ev.target.checked,
                    };
                  });
                }}
                id="show_constants"
                value={filters.constants}
                label={t('components.request.data.table.show_constants')}
              />
              <Form.Check
                size="sm"
                type="switch"
                className="mb-0"
                id="show_step_metadata"
                onChange={(ev) => {
                  setFilters((prevFilters) => {
                    return {
                      ...prevFilters,
                      constants: ev.target.checked,
                    };
                  });
                }}
                value={filters.step_metadata}
                label={t('components.request.data.table.show_step_metadata')}
              />
            </Dropdown.Menu>
          </Dropdown>
        </InputGroup>
        <div className="d-flex align-items-center mt-1">
          {!inIframe && (
            <div className="d-flex align-items-center">
              <Form.Label className="mb-0 me-2">
                {t('components.request.data.table.current_user')}
              </Form.Label>
              <Form.Select
                size="sm"
                placeholer={t('components.request.data.table.current_user')}
                id="current_user"
                style={{
                  width: '250px',
                }}
                value={currentUser || undefined}
                onChange={(ev) => {
                  setCurrentUser(ev.target.value);
                }}
              >
                {availableCurrentUserList.map((user) => (
                  <option value={user.value} key={user.value}>
                    {user.label}
                  </option>
                ))}
              </Form.Select>
            </div>
          )}
          {hasMasterData && (
            <Badge
              onClick={toggleMaster}
              pill
              className="ms-1 cursor-pointer"
              bg={inMaster ? 'primary' : 'secondary'}
            >
              Master
            </Badge>
          )}
        </div>
      </div>
      {inIframe ? (
        <Table size="sm" hover striped bordered className="fs--1">
          <tbody>
            {items.map((item, index) => {
              return (
                <RequestDataRow item={item} key={index} search={search}>
                  {DataActions && (
                    <DataActions path={item.path} onPathSelect={onPathSelect} />
                  )}
                </RequestDataRow>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <MaxHeightContainer
          ref={ref}
          id="request-data-table"
          offset={
            $('#request-data-card-header').height() +
            $('#request-data-search-bar').height()
          }
        >
          <Table size="sm" hover striped bordered className="fs--1">
            <tbody>
              <ViewportList
                viewportRef={ref}
                items={items}
                overscan={10} // Add buffer rows above and below
                itemSize={50}
                renderSpacer={({ ref, style }) => (
                  <tr style={style} ref={ref} />
                )}
              >
                {(item) => (
                  <RequestDataRow item={item} key={item.index} search={search}>
                    {DataActions && (
                      <DataActions
                        path={item.path}
                        onPathSelect={onPathSelect}
                      />
                    )}
                  </RequestDataRow>
                )}
              </ViewportList>
            </tbody>
          </Table>
        </MaxHeightContainer>
      )}
    </>
  );
};

export default RequestDataTable;
