import React from 'react';
import copy from 'clipboard-copy';
import { toast } from 'react-toastify';
import { get, method } from 'lodash';

const notify = (text) => {
  toast.success(text, {
    position: 'bottom-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: 'colored',
  });
};

const getTextToCopy = (txt) => `{{ ${txt} }}`;

export const availableMethods = [
  {
    method: "p(key, placeholder = '-')",
    examples: ["{{p('policy_owner.first_name', 'N/A')}}"],
    description:
      "Prints the value of the key (in example : 'policy_owner.first_name', if the value is not accessible or defined in request it will print '-'. This can be changed by the placeholder parameter",
    copy: (path, method) => {
      if (method) {
        console.log('path', path);
        return method(getTextToCopy(`p('${path}')`));
      }
      return copy(getTextToCopy(`p('${path}')`)).then(() =>
        notify('Copied as value'),
      );
    },
    copyIcon: ['fa', 'fa-paragraph'],
  },
  {
    method: 'a(key)',
    examples: ["{{FOR car IN a('cars.cars_multi')}}"],
    description:
      "Required to iterate on every iteration of a block Multi. Also guarantee to have an array even if the value behind the path doesn't exists (template generation can fail if value is not an array)",
    copy: (path, method) => {
      if (method) {
        return method(getTextToCopy(`a('${path}')`));
      }
      return copy(getTextToCopy(`a('${path}')`)).then(() =>
        notify('Copied as array'),
      );
    },
    copyIcon: ['fa', 'fa-table'],
  },
  {
    method: 'price(key, options, keyAsValue)',
    examples: [
      "{{price('premiums.total')}} -> 1000,13 €",
      "{{price('premiums.total', { symbol: '$' })}} -> 1000,13 $",
      "{{price('premiums.total', { precision: 1 })}} -> 1000,1 €",
      "{{price('premiums.total', { decimal: '.' })}} -> 1000.13 €",
      "{{price('premiums.total', { pattern: '! #' })}} -> € 1000,13",
      '{{price(150000, null, true)}} -> 150 000 €',
      "{{price('path.that_leads.to.not_a_number_value', null, false, 'Not found')}} -> Not found",
      "{{price(undefined, null, true, 'Not found')}} -> Not found",
    ],
    description:
      'Prints the value and formats as a price (space separator & € symbol). "options" allows to customize the format. "keyAsValue" (boolean), allows to format directly a value passed as key. The 4th parameter is a fallback value that will be used if the value is not a number',
    copy: (path, method) => {
      if (method) {
        return method(getTextToCopy(`price('${path}')`));
      }
      return copy(getTextToCopy(`price('${path}')`)).then(() =>
        notify('Copied as price'),
      );
    },
    copyIcon: ['fa', 'fa-dollar-sign'],
  },
  {
    method: 'checkBool(key)',
    examples: ["{{checkBool('premiums.liability.selected')}}"],
    description:
      'Utility to use in template conditions, in order to check if value is true or false',
    copy: (path, method) => {
      if (method) {
        return method(getTextToCopy(`checkBool('${path}')`));
      }
      return copy(getTextToCopy(`checkBool('${path}')`)).then(() =>
        notify('Copied as check boolean'),
      );
    },
    copyIcon: ['fa', 'fa-circle-check'],
    copyTitle: 'Copy as check boolean',
  },
  {
    method: 'moment(key)',
    examples: ["{{moment('policy_owner.date_of_birth', 'DD/MM/YYYY')}}"],
    description:
      'Prints the value and formats a date as you want all documentation is available on extra documentation links',
    documentationLinks: [{ link: 'https://momentjs.com/', name: 'momentjs' }],
    copy: (path, method) => {
      if (method) {
        return method(getTextToCopy(`moment('${path}').format('DD/MM/YYYY')`));
      }
      return copy(getTextToCopy(`moment(${path}).format('DD/MM/YYYY')`)).then(
        () => notify('Copied as date'),
      );
    },
    copyIcon: ['fa', 'fa-calendar'],
    copyTitle: 'Copy as date',
  },
  {
    method: 't(key)',
    examples: [
      '{{t(\'policy_owner.date_of_birth\')}} -> will looks in translation for "translations.fr.policy_owner.date_of_birth" ',
      '{{t(policy_owner_kind)}} -> will looks in translation for the VALUE of policy_owner_kind "translations.fr.company"',
      "{{t(policy_owner_kind.selected_list_item.__label)}} -> if value of provided key is an object with {fr: '',nl: ''} it will automatically get the locale ",
    ],
    description:
      'It will automatically looks on the available translations. The locale used for the template is the one defined when uploading the document template in the product factory',
  },
  {
    method: 'i(key, widthInCm, angle)',
    examples: [
      '{{IMAGE i(\'kitchen.uploads.0.blob.file_url\')}} -> will looks in data for "kitchen.uploads.0.blob.file_url" and prints the image',
      '{{IMAGE i(\'kitchen.uploads.0.blob.file_url\', 5)}} -> will looks in data for "kitchen.uploads.0.blob.file_url" and prints the image at 5cm of width',
      '{{IMAGE i(\'kitchen.uploads.0.blob.file_url\', 20, 90)}} -> will looks in data for "kitchen.uploads.0.blob.file_url" and prints the image at 20cm of width and rotated at +90°',
    ],
    description:
      'Method used to print image in the document, the size of the image can be specified (in cm) and if resized the image will keep its aspect ratio. Also we can set the rotation angle of the picture, useful if printing a large image on a full page.',
    copy: (path, method) => {
      if (method) {
        return method(getTextToCopy(`IMAGE i('${path}')`));
      }
      return copy(getTextToCopy(`IMAGE i('${path}')`)).then(() =>
        notify('Copied as image'),
      );
    },
    copyIcon: ['fa', 'fa-image'],
  },
  {
    method: 'signature',
    examples: ['{{signature}} -> will insert the signature field'],
    description: 'Method used to insert the signature field in the document',
    copy: () => {
      if (method) {
        return method(getTextToCopy(`signature`));
      }
      return copy(getTextToCopy(`signature`)).then(() =>
        notify('Copied signature code'),
      );
    },
    copyIcon: ['fa', 'fa-signature'],
  },
];

const WatsonDocumentation = () => {
  return (
    <div className="bg-light">
      <h4>Available methods</h4>
      {availableMethods.map((availableMethod, index) => {
        return (
          <div key={index} className="mb-3">
            <h5>{availableMethod.method}</h5>
            <p className="mb-1">{availableMethod.description}</p>
            <div className="font-monospace bg-light p-2 mb-1">
              <ul>
                {availableMethod.examples.map((example, index) => (
                  <li key={index}>{example}</li>
                ))}
              </ul>
            </div>
            {availableMethod.documentationLinks && (
              <p>
                {availableMethod.documentationLinks.map((doc, index) => (
                  <a
                    href={doc.link}
                    target="_blank"
                    key={index}
                    className="mr-2"
                  >
                    {doc.name}
                  </a>
                ))}
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default WatsonDocumentation;
